import { Variant } from "./class-variant.interface";
import { twJoin, twMerge } from 'tailwind-merge'

export function getVariantClasses<T extends string>(
  config: Variant<T>,
  selectedVariants: Partial<Record<T, string>> = {},
  custom: string[] = []
): string {
  const { base, variants, defaultVariants } = config;
  const classes = [...base];

  (Object.keys(variants) as T[]).forEach(variant => {
    const variantValue = selectedVariants[variant] || defaultVariants && defaultVariants[variant];

    if (variantValue && variants[variant][variantValue] && variants[variant][variantValue].length) {
      classes.push(...variants[variant][variantValue]);
    }
  });

  classes.push(...custom)

  return twMerge(classes);
}

// export function getVariantClasses<T extends string>(
//   config: Variant<T>,
//   selectedVariants: Partial<Record<T, string>> = {}
// ): string {
//   const { base, variants, defaultVariants } = config;
//   const classes = [base];

//   (Object.keys(variants) as T[]).forEach(variant => {
//     const variantValue = selectedVariants[variant] || defaultVariants[variant] || null;
//     if (variantValue && variants[variant][variantValue]) {
//       classes.push(variants[variant][variantValue]);
//     }
//   });

//   return classes.join(' ');
// }