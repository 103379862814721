import { getVariantClasses, Variant } from '@advn/shared/web/util/class-variant';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: false,
  selector: 'advn-alert',
  templateUrl: './alert.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent {
  @Input() width = "360px";
}
