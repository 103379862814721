import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'advn-alert-prefix',
  template: '<ng-content></ng-content>'
})
export class AlertPrefixComponent {
  @Input() title: string | undefined = undefined;
  @Input() titleStyle: string = 'text-center';
}