import { AfterViewInit, ChangeDetectionStrategy, Component, input, OnInit, ViewChild, Input } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { Subject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'advn-confirm-dialog',
  templateUrl: './confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ConfirmDialogComponent {

  @ViewChild('dialog') dialog:
    | DialogComponent<ConfirmDialogComponent>
    | undefined;

  @Input() variant: 'positive' | 'negative' = 'positive';

  dialogStyle = 'w-[360px]';
  title = '';
  message = '';
  confirm: Subject<boolean> = new Subject<boolean>();

  async onConfirmClick(value: boolean) {
    this.confirm.next(value);
    this.dialog?.close();
  }

  get variantcolor1() {
    const colorvariant = {
      positive: ' bg-[#F6F9FE]',
      negative: ' bg-[#FFF5F6]',
    };
    return colorvariant[this.variant];
  }

  get variantcolor2() {
    const colorvariant = {
      positive: ' bg-[#E8EFFD]',
      negative: ' bg-[#FFEBED]',
    };
    return colorvariant[this.variant];
  }

  get varianticoncolor() {
    const colorvariant = {
      positive: ' text-[#1041A3] w-8 h-8',
      negative: ' text-[#E53935] w-8 h-8',
    };
    return colorvariant[this.variant];
  }

  getConfirmation$() {
    return this.confirm.asObservable();
  }

  setMessage(message: string) {
    this.message = message
  }
}
