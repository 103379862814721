<advn-dialog [dialogStyle]="dialogStyle" #dialog>
   <div class="flex justify-between">
      <div class="flex">
         <div class="flex items-center px-5 py-2.5" [ngClass]="variantcolor">
            <svg-icon
               [src]="varianticon"
               svgClass="w-10 h-10 text-gray-100"></svg-icon>
         </div>
         <div class="flex flex-col px-[24px] py-[10px]">
            <span class="text-h5 text-start">{{variantTitle}}</span>
            <span class="text-start">{{ message }}</span>
         </div>
      </div>

      <div class="flex items-center p-5">
         <button advn-icon-button color="secondary" icon="heroXMark" (click)="close()">
            <svg-icon
               src="assets/icons/outline/x.svg"
               svgClass="w-6 h-6 text-gray-900"></svg-icon>
         </button>
      </div>
   </div>
</advn-dialog>
