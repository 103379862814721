import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DialogService<T> {
  private componentRefs: ComponentRef<T>[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  open(component: Type<T>) {
    // const ref = viewContainerRef.createComponent(component, { injector: this.injector });

    const ref = this.componentFactoryResolver
      .resolveComponentFactory<T>(component)
      .create(this.injector);

    this.appRef.attachView(ref.hostView);

    const domElem = (ref.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.componentRefs.push(ref);

    // // return this.componentRef[this.componentRef.length - 1].instance;
    return ref.instance;
  }

  // open(component: Type<T>): T {
  //   const componentRef = this.appRef.bootstrap(component, document.createElement('div'));

  //   this.componentRefs.push(componentRef);

  //   return componentRef.instance;
  // }

  async close(): Promise<void> {
    if (this.componentRefs.length) {
      const ref = this.componentRefs.pop();

      if (ref) {
        this.appRef.detachView(ref.hostView);
        ref.destroy();
      }
    }
  }
}