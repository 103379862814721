<section [class.open]="display" class="fixed z-10 inset-0 overflow-hidden">
   <div
      class="flex items-center justify-center w-screen h-screen text-center sm:p-0 bg-gray-900 bg-opacity-60">
      <div
         [ngClass]="dialogStyle"
         class="relative overflow-hidden inline-block text-primary-default bg-surface rounded-[10px] shadow-xl"
         role="dialog"
         aria-modal="true"
         aria-labelledby="modal-headline">
         <div
            class="rounded-t-xl font-bold py-4 px-4 text-OnSurface bg-header flex justify-between"
            *ngIf="title">
            <h5 class="text-OnSurface" [ngClass]="titleStyle">
               {{ title }}
            </h5>

            <button advn-icon-button size="lg" (click)="close()">
               <ng-icon name="heroXMark"></ng-icon>   
            </button>
         </div>

         <div class="overflow-auto h-[calc(100%-56px)] flex flex-col">
            <ng-content></ng-content>
         </div>
      </div>
   </div>
</section>
