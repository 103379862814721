import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { AlertDialogComponent } from './alert/alert.component';
import { ConfirmDialogComponent } from './confirm/confirm.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonDirective } from '@advn/shared/web/ui/button';
import { NgIcon,NgIconsModule } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';
import {IconButtonDirective} from '@advn/shared/web/ui/icon-button';


@NgModule({
   declarations: [
      DialogComponent,
      AlertDialogComponent,
      ConfirmDialogComponent,
   ],
   imports: [
      CommonModule,
      ReactiveFormsModule,
      AngularSvgIconModule,
      ButtonDirective,
      IconButtonDirective,
      NgIconsModule.withIcons({ heroXMark }),
      NgIcon,
   ],
   providers: [DialogService],
   exports: [DialogComponent, AlertDialogComponent, ConfirmDialogComponent],
})
export class DialogModule {}
