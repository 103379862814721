import { Variant, getVariantClasses } from '@advn/shared/web/util/class-variant';
import { Directive, ElementRef, HostBinding, Input, InputSignal, OnChanges, OnInit, Renderer2, SimpleChanges, ViewContainerRef, effect, input, model, signal } from '@angular/core';
import { NgIconComponent } from '@ng-icons/core';
import { SvgIconComponent } from 'angular-svg-icon';

type IconButtonVariants = 'variant' | 'rounded' | 'color' | 'size';

// const iconSize = {
//   xs: 'w-4 h-4',
//   sm: 'w-5 h-5',
//   md: 'w-5 h-5',
//   lg: 'w-6 h-6',
//   xl: 'w-6 h-6',
// }

const buttonConfig: Variant<IconButtonVariants> = {
  base: ['relative', 'flex', 'items-center', 'justify-center', 'outline-none', 'focus:outline-none', 'disabled:opacity-50', 'cursor-pointer'],
  variants: {
    variant: {
      default: ['border-none', 'bg-opacity-0', 'outline-0', 'focus:outline-0', '[&:not(:disabled)]:hover:opacity-50'],
      outline: ['border', 'bg-opacity-0'],
      filled: []
    },
    color: {
      primary: ['bg-primary', 'border-primary', '[&:not(:disabled)]:hover:outline-primary'],
      secondary: ['bg-secondary', 'border-secondary', '[&:not(:disabled)]:hover:outline-secondary'],
      tertiary: ['bg-tertiary', 'border-tertiary', '[&:not(:disabled)]:hover:outline-tertiary'],
      accent: ['bg-accent', 'border-accent', '[&:not(:disabled)]:hover:outline-accent'],
      info: ['bg-info', 'border-info', '[&:not(:disabled)]:hover:outline-info'],
      warn: ['bg-warn', 'border-warn', '[&:not(:disabled)]:hover:outline-warn'],
      error: ['bg-error', 'border-error', '[&:not(:disabled)]:hover:outline-error']
    },
    size: {
      xs: ['w-6', 'h-6', 'text-md'],
      sm: ['w-7', 'h-7', 'text-lg'],
      md: ['w-8', 'h-8', 'text-xl'],
      lg: ['w-9', 'h-9', 'text-2xl'],
      xl: ['w-10', 'h-10', 'text-3xl'],
    },
    rounded: {
      none: ['rounded-none'],
      small: ['rounded-md'],
      normal: ['rounded-xl'],
      large: ['rounded-2xl'],
      full: ['rounded-full'],
    }
  },
  // defaultVariants: {
  //   variant: 'default',
  //   rounded: 'normal',
  //   color: 'accent',
  //   size: 'md',
  // },
};

@Directive({
  selector: '[advn-icon-button]'
})
export class IconButtonDirective implements OnInit {
  @Input() variant: 'default' | 'outline' | 'filled' = 'default';
  @Input() rounded: 'none' | 'small' | 'normal' | 'large' | 'full' = 'normal';
  @Input() color: 'primary' | 'secondary' | 'tertiary' | 'info' | 'accent' | 'warn' | 'error' = 'secondary';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

  icon = input<string>();
  // iconSize = model<string>(iconSize[this.size])

  @HostBinding('class') classes: string = this.buttonClasses;

  constructor(
    private ref: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.classes = this.buttonClasses
    // this.iconSize.set(iconSize[this.size]);

    if (this.icon()) {
      // const componentRef = this.viewContainerRef.createComponent(SvgIconComponent);
      // componentRef.instance.src = this.icon;
      // componentRef.instance.svgClass = this.iconSize;

      const componentRef = this.viewContainerRef.createComponent(NgIconComponent);
      componentRef.setInput('name', this.icon());
      
      this.renderer.appendChild(this.ref.nativeElement, componentRef.location.nativeElement);
    }
  }

  get buttonClasses() {
    return getVariantClasses(buttonConfig, { variant: this.variant, rounded: this.rounded, color: this.color, size: this.size }, [this.textClasses])
  }

  get textClasses() {
    return this.variant == 'filled' ? `text-${this.color}-foreground` : `text-${this.color}`  // return this.variant == 'outline' ? `text-${this.color}` : `text-${this.color}-foreground`
  }
}