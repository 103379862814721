import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import { DialogService } from './dialog.service';
import {provideIcons} from '@ng-icons/core';
import {heroXMark} from '@ng-icons/heroicons/outline';

@Component({
  standalone: false,
  selector: 'advn-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent<T> {
  @Input() dialogStyle = 'w-[800px]';
  @Input() title: string | undefined = undefined;
  @Input() titleStyle: string = 'text-center';
  @Output() onClosed = new EventEmitter<unknown>();

  display = true;

  constructor(private dialogService: DialogService<T>) { }

  openDialog(component: Type<T>): T {
    return this.dialogService.open(component);
  }

  async close(data?: unknown): Promise<void> {
    this.display = false;
    await this.dialogService.close();

    this.onClosed.emit(data)
  }
}