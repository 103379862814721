<advn-alert [width]="width">
   <advn-alert-prefix [ngClass]="prefixStyle">
      <ng-icon [name]="icon" />
   </advn-alert-prefix>

   <advn-alert-content>
      <div class="font-bold text-start" *ngIf="title">{{ title }}</div>
      <div class="supports-[overflow-wrap:anywhere]:[overflow-wrap:anywhere]">
         {{ message }}
      </div>
   </advn-alert-content>

   <advn-alert-suffix>
      <button
         advn-icon-button
         color="secondary"
         size="lg"
         icon="heroXMark"
         (click)="close()"></button>
   </advn-alert-suffix>
</advn-alert>
