import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AlertComponent } from '../alert.component';
import { Subject } from 'rxjs';
import { AlertService } from '../alert.service';
import { AlertRef } from '../alert-ref';
import { ALERT_DATA } from '../alert-token';
import { getVariantClasses, Variant } from '@advn/shared/web/util/class-variant';


const icons = {
   info: 'heroCheckCircle',
   warn: 'heroExclamationCircle',
   error: 'heroXCircle',
};

type AlertVariants = 'variant';

const alertConfig: Variant<AlertVariants> = {
   base: ['relative', 'h-full', 'flex', 'items-center', 'justify-center'],
   variants: {
      variant: {
         info: ['bg-info', 'text-5xl'],
         warn: ['bg-warn', 'text-4xl'],
         error: ['bg-error', 'text-4xl'],
      },
   },
};

@Component({
   standalone: false,
   selector: 'advn-alert-default',
   templateUrl: './alert-default.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDefaultComponent {
   @Input() variant: 'info' | 'warn' | 'error';

   @Input() width: string;
   @Input() title: string | undefined = undefined;
   @Input() message: string;

   constructor(
      private readonly alertRef: AlertRef,
      @Inject(ALERT_DATA)
      private data: {
         variant?: 'info' | 'warn' | 'error';
         width?: string;
         title?: string;
         message?: string;
      },
   ) {
      this.variant = data.variant || 'info';
      this.width = data.width || '380px';
      this.title = data.title || undefined;
      this.message = data.message || 'Something went wrong!';
   }

   get icon() {
      return icons[this.variant];
   }

   get prefixStyle() {
      return getVariantClasses(alertConfig, { variant: this.variant });
   }

   close() {
      this.alertRef.close();
   }
}
