<advn-dialog [dialogStyle]="dialogStyle" #dialog>
   <div class="px-4 pt-5 pb-4 flex justify-center items-center gap-4">
      <div class="rounded-[30px] p-2" [ngClass]="variantcolor1">
         <div class="rounded-[30px] p-1.5" [ngClass]="variantcolor2">
            <svg-icon
               src="assets/icons/outline/exclamation-circle.svg"
               [svgClass]="varianticoncolor"></svg-icon>
         </div>
      </div>

      <div class="flex flex-col text-left">
         <span class="text-h5">{{ title }}</span>
         <span>{{ message }}</span>
      </div>
   </div>
   <div class="flex justify-end p-2.5 bg-gray-100">
      <button
         advn-button
         variant="outline"
         class="mx-2.5"
         (click)="onConfirmClick(false)">
         Cancel
      </button>
      <button advn-button color="accent" (click)="onConfirmClick(true)">
         Submit
      </button>
   </div>
</advn-dialog>
