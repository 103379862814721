import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';

export class AlertRef<T = any> {
   private afterClosedSubject = new Subject<T | undefined>();
   public afterClosed$: Observable<T | undefined> =
      this.afterClosedSubject.asObservable();

   constructor(private overlayRef: OverlayRef) {}

   close(result?: T) {
      this.overlayRef.dispose();
      this.afterClosedSubject.next(result);
      this.afterClosedSubject.complete();
   }
}
