import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonDirective } from '@advn/shared/web/ui/button';
import { IconButtonDirective } from '@advn/shared/web/ui/icon-button';

import { AlertService } from './alert.service';
import { AlertComponent } from './alert.component';
import { AlertPrefixComponent } from './alert-prefix.component';
import { AlertContentComponent } from './alert-content.component';
import { AlertSuffixComponent } from './alert-suffix.component';
import { AlertDefaultComponent } from './default/alert-default.component';
import { NgIcon } from '@ng-icons/core';

@NgModule({
   imports: [
      CommonModule,
      ButtonDirective,
      IconButtonDirective,
      NgIcon
   ],
   providers: [
      AlertService,
   ],
   declarations: [
      AlertComponent,
      AlertPrefixComponent,
      AlertContentComponent,
      AlertSuffixComponent,
      AlertDefaultComponent,
   ],
   exports: [
      AlertComponent,
      AlertPrefixComponent,
      AlertContentComponent,
      AlertSuffixComponent,
      AlertDefaultComponent,

   ],
})
export class AlertModule { }
