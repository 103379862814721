import { AfterViewInit, ChangeDetectionStrategy, Component, Input, input, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { Subject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'advn-alert-dialog',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  @ViewChild('dialog') dialog:
    | DialogComponent<AlertDialogComponent>
    | undefined;

  @Input() variant: 'success' | 'warning' | 'failed' = 'success';

  dialogStyle = 'w-[380px]';
  title = '';
  message = '';
  alert: Subject<boolean> = new Subject<boolean>();

  width = 'w-auto';

  constructor() { }

  get variantcolor() {
    const colorvariant = {
      success: ' bg-positive',
      warning: ' bg-caution',
      failed: ' bg-negative',
    };
    return colorvariant[this.variant];
  }

  get varianticon() {
    const iconvariant = {
      success: 'assets/icons/outline/check-circle.svg',
      warning: 'assets/icons/outline/exclamation-circle.svg',
      failed: 'assets/icons/outline/x-circle.svg',
    };
    return iconvariant[this.variant];
  }

  get variantTitle(){
    const titlevariant = {
      success: 'Success!',
      warning: 'Warning!',
      failed: 'Failed!',
    }
    return titlevariant[this.variant];
  }

  async close() {
    this.alert.next(true);
    this.dialog?.close();
  }
  

  getAlert$() {
    return this.alert.asObservable();
  }

  setMessage(message: string) {
    this.message = message
  }
}
