import { Variant, getVariantClasses } from '@advn/shared/web/util/class-variant';
import { Directive, HostBinding, Input, OnInit, input } from '@angular/core';

type ButtonVariants = 'variant' | 'rounded' | 'color' | 'size';

const buttonConfig: Variant<ButtonVariants> = {
  base: ['relative', 'flex', 'items-center', 'justify-center', 'outline-none', 'cursor-pointer', 'focus:outline-none', 'disabled:opacity-50', 'shadow-default'],
  variants: {
    variant: {
      default: ['border-0', '[&:not(:disabled)]:hover:outline-0', '[&:not(:disabled)]:hover:text-bold'],
      outline: ['border', 'shadow-none', 'bg-opacity-0', 'outline-0', '[&:not(:disabled)]:hover:opacity-70'],
      filled: ['border-0', '[&:not(:disabled)]:hover:opacity-90', '[&:not(:disabled)]:hover:text-bold'],
      text: ['border-0', 'bg-opacity-0', 'outline-none', 'shadow-none', '[&:not(:disabled)]:hover:text-opacity-80', '[&:not(:disabled)]:hover:outline-0']
    },
    color: {
      default: ['text-gray-900'],
      primary: ['bg-primary', 'text-primary-foreground', 'border-primary', '[&:not(:disabled)]:hover:outline-primary'],
      secondary: ['bg-secondary', 'text-secondary-foreground', 'border-secondary', '[&:not(:disabled)]:hover:outline-secondary'],
      tertiary: ['bg-tertiary', 'border-tertiary', '[&:not(:disabled)]:hover:outline-tertiary'],
      accent: ['bg-accent', 'text-accent-foreground', 'border-accent', '[&:not(:disabled)]:hover:outline-accent'],
      info: ['bg-info', 'text-info-foreground', 'border-info', '[&:not(:disabled)]:hover:outline-info'],
      warn: ['bg-warn', 'text-warn-foreground', 'border-warn', '[&:not(:disabled)]:hover:outline-warn'],
      error: ['bg-error', 'text-error-foreground', 'border-error', '[&:not(:disabled)]:hover:outline-error']
    },
    size: {
      xs: ['h-7', 'text-xs', 'px-4', 'py-2.5'],
      sm: ['h-8', 'font-semibold', 'text-xs', 'px-5', 'py-2.5'],
      md: ['h-9', 'font-semibold', 'text-sm', 'px-7', 'py-2.5'],
      lg: ['h-10', 'font-semibold', 'text-md', 'px-9', 'py-2.5'],
      xl: ['h-11', 'font-bold', 'text-lg', 'px-11', 'py-2.5'],
    },
    rounded: {
      none: ['rounded-none'],
      small: ['rounded-md'],
      normal: ['rounded-xl'],
      large: ['rounded-2xl'],
      full: ['rounded-full'],
    },
  },
  defaultVariants: {
    variant: 'default',
    rounded: 'normal',
    color: 'accent',
    size: 'md',
  },
};

@Directive({
  selector: '[advn-button]'
})
export class ButtonDirective implements OnInit {
  @Input() variant: 'default' | 'outline' | 'filled' | 'text' = 'filled';
  @Input() rounded: 'none' | 'small' | 'normal' | 'large' | 'full' = 'normal';
  @Input() color: 'default' | 'primary' | 'secondary' | 'tertiary' | 'info' | 'accent' | 'warn' | 'error' = 'primary';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

  @HostBinding('class') classes: string = this.variantClasses;

  ngOnInit(): void {
    this.classes = this.variantClasses
  }

  get variantClasses() {
    return getVariantClasses(
      buttonConfig, {
      variant: this.variant,
      rounded: this.rounded,
      color: this.color,
      size: this.size,
    },
      [this.textClasses])
  }

  get textClasses() {
    if (this.variant === 'outline' || this.variant === 'text') {
      return `text-${this.color}`;
    }
    return `text-${this.color}-foreground`;
  }
}

