import { Injectable, Injector, ApplicationRef, ComponentFactoryResolver, ComponentRef, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { AlertRef } from './alert-ref';
import { ALERT_DATA } from './alert-token';

@Injectable({ providedIn: 'root' })
export class AlertService {
   private overlayRef: OverlayRef | null = null;
   private onClosed = new Subject<unknown>();

   constructor(
      private overlay: Overlay,
      private injector: Injector,
   ) { }

   open<T>(
      component: Type<T>,
      data?: unknown,
      closeOnBackdropClick: boolean = true,
   ): AlertRef<T> {
      // this.overlayRef = this.overlay.create({
      //   hasBackdrop: true,
      //   positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      // });

      const overlayConfig = new OverlayConfig({
         hasBackdrop: true,
         positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically(),
      });

      const overlayRef: OverlayRef = this.overlay.create(overlayConfig);

      // Create a AlertRef instance to return to the caller
      const alertRef = new AlertRef(overlayRef);

      // Create an injector with the providers using Injector.create
      const injector = Injector.create({
         providers: [
            { provide: AlertRef, useValue: alertRef },
            { provide: ALERT_DATA, useValue: data },
         ],
         parent: this.injector,
      });

      // Attach the component portal
      const portal = new ComponentPortal(component, null, injector);
      overlayRef.attach(portal);

      if (closeOnBackdropClick)
         overlayRef.backdropClick().subscribe(() => alertRef.close());

      return alertRef;
   }
}
